import axios from 'axios';

// DEV
// export const API_URL = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/';
// export const API_URL_MAIN = 'https://ws-dev-main.digidesa.com/api/';
// const API_URL2 = 'https://ws-marketplace-desa-univ.digidesa.com/api/v1/mobile/';
// export const API_URL_DIGIDESA = 'https://ws-dgd-spt0761.digidesa.com/api/v1/web/';
// export const API_URL_WISATA = 'https://ws-dev-wisata.digidesa.com/api/v1/web/';

//demo marketplace
// const API_URL = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/web/';
// const API_URL2 = 'https://ws-demo-desa-marketplace.digidesa.com/api/v1/mobile/';

// PROD
export const API_URL = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/web/';
export const API_URL_MAIN = 'https://ws-prod-main.digidesa.com/api/';
const API_URL2 = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/mobile/';
export const API_URL_DIGIDESA = 'https://ws-demo-desa.digidesa.com/api/v1/web/';
export const API_URL_WISATA = 'https://ws-dev-wisata.digidesa.com/api/v1/web/';


localStorage.setItem('API_URL', API_URL);

export function GetDigidesa(table, id, cb) {
	let token = sessionStorage.getItem('access_token') ? sessionStorage.getItem('access_token') : 'public';

	if (id === null) {
		axios
			.get(`${API_URL_DIGIDESA + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				if (err.response) {
					cb(err.response.data);
				}
			});
	} else {
		axios
			.get(`${API_URL_DIGIDESA + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Get(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function GetMobile(table, id, cb) {
	console.log(table);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`)
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`)
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function GetMarketplace(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function Delete(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	axios
		.delete(`${API_URL + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
		.then((response) => response.status)
		.then((status) => {
			cb(status);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function PostWisata(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL_WISATA + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL_WISATA + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function PatchWisata(table, id, data, cb) {
	let token = localStorage.getItem("access_token")
		? localStorage.getItem("access_token")
		: "";
	axios
		.patch(`${API_URL_WISATA + table}`, data, { 
			headers: { Authorization: `Bearer ${token}` },
			validateStatus: false,
		})
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function GetWisata(table, id, cb) {
	let token = localStorage.getItem("access_token")
		? localStorage.getItem("access_token")
		: "";

	// //console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL_WISATA + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				if (err.response) {
					cb(err.response.data);
				}
			});
	} else {
		axios
			.get(`${API_URL_WISATA + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((err) => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then((response) => {
			cb(response);
		})
		.catch((response) => {
			cb(response);
		});
}
export function PostStatus(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export function PostUser(table, id, data, cb) {
	if (id === null) {
		axios
			.post('http://localhost/ppdx/web/' + table, data, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then((response) => response.status)
			.then((status) => {
				cb(status);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

// BO Main
export function GetMain(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL_MAIN + table}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL_MAIN + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
			.then((response) => response.data)
			.then((data) => {
				cb(data);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export const GetMainAwait = async (table, id, cb) => {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	if (id === null) {
		try {
			const resp = await axios.get(`${API_URL_MAIN + table}`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				console.log(resp);
				return resp
		} catch (err) {
			return err
		}
	} else {
		try {
			const resp = axios.get(`${API_URL_MAIN + table}/${id}`, {
					headers: { Authorization: `Bearer ${token}` }
				})
				console.log(resp);
				return resp
		} catch (err) {
			return err
		}
	}
}

export function PostMain(table, id, data, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL_MAIN + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL_MAIN + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then((response) => {
				cb(response);
			})
			.catch((err) => {
				cb(err);
			});
	}
}

export const PostMainAwait = async (table, id, data, cb) => {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';
	// console.log(token)
	if (id === null) {
		try {
			const resp = await axios.post(`${API_URL_MAIN + table}`, data, { headers: { Authorization: `Bearer ${token}` }, validateStatus: false })
			console.log(resp);
			return resp
		} catch (err) {
			return err
		}
	} else {
		try {
			const resp = axios.patch(`${API_URL_MAIN + table}/${id}`, data, {
						headers: { Authorization: `Bearer ${token}` },
						validateStatus: false
					})
			console.log(resp);
			return resp
		} catch (err) {
			return err
		}
	}
}

export function DeleteMain(table, id, cb) {
	let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '';

	axios
		.delete(`${API_URL_MAIN + table}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
		.then((response) => response.status)
		.then((status) => {
			cb(status);
		})
		.catch((err) => {
			cb(err);
		});
}

export default API_URL;
